import { filter, head, last, map, sortBy } from 'lodash-es';
import { Financing, type FinancingEvaluationResult } from '../typedefs';
import NumberUtil from './numberUtil';

function evaluateFinancingForProject(
  financing: Financing | undefined,
  priceAfterRebates: number | undefined,
  monthlyUtilitySavings: number | undefined,
): FinancingEvaluationResult | undefined {
  if (!financing) return;

  const loanPrincipal = LoanUtil.getLoanPrincipal({
    priceAfterRebates,
    financing,
  });
  if (!loanPrincipal) return;

  const monthlyPayment = LoanUtil.getMonthlyPayment({ loanPrincipal, financing });
  if (!monthlyPayment) return;

  const roundedMonthlyPayment = NumberUtil.roundToSignificantDigits(monthlyPayment, 4);

  const monthlyCostAfterSavings = LoanUtil.getMonthlyCostAfterSavings({
    monthlyPayment: roundedMonthlyPayment,
    monthlySavings: monthlyUtilitySavings,
  });

  return {
    financing,
    evaluation: {
      loanPrincipal,
      monthlyPayment: roundedMonthlyPayment,
      monthlyCostAfterSavings,
    },
  };
}

const LoanUtil = {
  pickBestFinancingForProject(
    financingOptions: Financing[],
    priceAfterRebates: number | undefined,
    monthlyUtilitySavings: number | undefined,
  ): FinancingEvaluationResult | undefined {
    // 1) pick largest loan principal amount
    // 2) if multiple, pick smallest monthly payment

    const financingEvaluations = map(financingOptions, (financing) => {
      return evaluateFinancingForProject(financing, priceAfterRebates, monthlyUtilitySavings);
    });
    if (financingEvaluations.length <= 1) {
      return head(financingEvaluations);
    }

    const filteredEvaluations = filter(
      financingEvaluations,
      (financingEval) => !!financingEval?.evaluation?.loanPrincipal && !!financingEval?.evaluation?.monthlyPayment,
    );
    if (filteredEvaluations.length <= 1) {
      return head(filteredEvaluations);
    }

    const sortedEvaluations = sortBy(filteredEvaluations, (financingEval) => financingEval?.evaluation?.loanPrincipal);
    const largestPrincipal = last(sortedEvaluations)?.evaluation?.loanPrincipal as number;

    const selectedEvaluations = filter(
      sortedEvaluations,
      (financingEval) => largestPrincipal === financingEval?.evaluation?.loanPrincipal,
    );
    if (selectedEvaluations.length <= 1) {
      return head(selectedEvaluations);
    }

    const secondarySortedEvaluations = sortBy(
      selectedEvaluations,
      (financingEval) => financingEval?.evaluation?.monthlyPayment,
    );
    const smallestMonthlyPayment = head(secondarySortedEvaluations)?.evaluation?.monthlyPayment as number;

    const secondarySelectedEvaluations = filter(
      secondarySortedEvaluations,
      (financingEval) => smallestMonthlyPayment === financingEval?.evaluation?.monthlyPayment,
    );

    return head(secondarySelectedEvaluations);
  },

  getLoanPrincipal({ priceAfterRebates, financing }: { priceAfterRebates?: number; financing?: Financing }) {
    if (priceAfterRebates && financing?.minPrincipal && priceAfterRebates < financing.minPrincipal) {
      return;
    }

    if (priceAfterRebates && financing?.maxPrincipal) {
      return Math.min(priceAfterRebates, financing.maxPrincipal);
    } else if (priceAfterRebates) {
      return priceAfterRebates;
    }
  },

  getDownPayment({ priceAfterRebates, loanPrincipal }: { priceAfterRebates?: number; loanPrincipal?: number }) {
    return priceAfterRebates && loanPrincipal && Math.max(priceAfterRebates - loanPrincipal, 0);
  },

  getMonthlyPayment({ loanPrincipal, financing }: { loanPrincipal?: number; financing?: Financing }) {
    if (loanPrincipal && financing) {
      const { apr, discountFactor, months } = financing;
      if (apr === 0 || discountFactor === undefined || discountFactor === null) {
        return loanPrincipal / months;
      }

      return loanPrincipal / discountFactor;
    }
  },

  getMonthlyCostAfterSavings({ monthlyPayment, monthlySavings }: { monthlyPayment?: number; monthlySavings?: number }) {
    return monthlyPayment !== undefined && monthlySavings !== undefined ? monthlyPayment - monthlySavings : undefined;
  },
};

export default LoanUtil;
