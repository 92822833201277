import autoBind from 'auto-bind';
import type { FormErrors, FormFields, FormFieldsTouched } from '../../typedefs';
import {
  MAX_EXISTING_SEER,
  MAX_VALID_INDOOR_TEMP,
  MAX_VALID_ROOM_SQFT,
  MAX_VALID_SQFT,
  MIN_EXISTING_SEER,
  MIN_VALID_ADDRESS_LENGTH,
  MIN_VALID_INDOOR_TEMP,
  MIN_VALID_ROOM_SQFT,
  MIN_VALID_SQFT,
  MIN_VALID_YEAR_BUILT,
} from '../constants';
import {
  DuctedDeliveryType,
  ExistingAcTonnage,
  ExternalUnitLocation,
  FurnaceType,
  LayoutType,
  NumExistingSystems,
  WholeHomeSystem,
} from '../enums';
import SizingModeUtil from '../sizingModeUtil';
import AbstractValidator from './abstractValidator';

export default class FormValidator extends AbstractValidator<FormFields> {
  touched: FormFieldsTouched;
  layout?: LayoutType;

  constructor(form: FormFields, touched: FormFieldsTouched, layout?: LayoutType) {
    super(form);
    this.touched = touched;
    this.layout = layout;
    autoBind(this);
  }

  validate(): FormErrors {
    this.validateIntro();
    this.validateAddress();
    this.validateContactInfo();
    switch (this.layout) {
      case LayoutType.SINGLE_ROOM:
        this.validateSingleRoomData();
        break;
      case LayoutType.MULTIPLE_ROOMS:
        this.validateHomeData();
        this.validateMultiSplitRoomData();
        this.validateHVACData();
        break;
      case LayoutType.WHOLE_HOME:
      case LayoutType.UNKNOWN:
      case LayoutType.REPLACE_EXISTING:
        this.validateHomeData();
        this.validateHVACData();
        break;
      default:
        break;
    }
    return this.errors;
  }

  validateIntro() {
    if (this.touched.layoutType) {
      this.isRequired('layoutType', 'heating and cooling solution');
    }
    if (this.touched.serviceType) {
      this.isRequired('serviceType', 'type of service');
    }
  }

  validateAddress() {
    const { zipCode, streetAddress } = this.form;
    if (this.touched.zipCode) {
      this.isRequired('zipCode', 'zip code');
      if (zipCode !== '') {
        this.checkZip(zipCode);
      }
    }

    if (this.touched.streetAddress) {
      if (streetAddress) {
        this.length('streetAddress', 'Address', MIN_VALID_ADDRESS_LENGTH);
        if (!zipCode && !this.touched.zipCode) {
          this.createError('streetAddress', `The selected address does not have a zip code! Please select another.`);
        }
      } else {
        this.isRequired('streetAddress', 'Address');
      }
    }
  }

  validateHomeData() {
    if (this.touched.sqFt) {
      this.isRequired('sqFt', 'square footage');
      if (this.form.sqFt !== '') {
        this.range('sqFt', 'square footage', MIN_VALID_SQFT, MAX_VALID_SQFT);
      }
    }

    if (this.touched.yearBuilt) {
      const currentYear = new Date().getFullYear();
      this.range('yearBuilt', 'year built', MIN_VALID_YEAR_BUILT, currentYear, false);
    }

    if (this.touched.ductedDelivery) {
      this.isRequired('ductedDelivery', 'type of ducting');
      this.isEnumMember('ductedDelivery', DuctedDeliveryType);
    }

    if (this.touched.externalUnitLocation) {
      this.isRequired('externalUnitLocation', 'external unit location');
      this.isEnumMember('externalUnitLocation', ExternalUnitLocation);
    }
  }

  hasTouchedMultiSplitRooms() {
    const { totalSmall, totalMedium, totalLarge, totalXLarge } = this.touched;
    return totalSmall || totalMedium || totalLarge || totalXLarge;
  }

  getTotalRooms() {
    const { totalSmall, totalMedium, totalLarge, totalXLarge } = this.form;
    return (
      this.toNumber(totalSmall) + this.toNumber(totalMedium) + this.toNumber(totalLarge) + this.toNumber(totalXLarge)
    );
  }

  validateMultiSplitRoomData() {
    if (this.hasTouchedMultiSplitRooms()) {
      this.checkRooms({ totalField: 'totalSmall', upperField: 'upperSmall', label: 'small room' });
      this.checkRooms({ totalField: 'totalMedium', upperField: 'upperMedium', label: 'medium room' });
      this.checkRooms({ totalField: 'totalLarge', upperField: 'upperLarge', label: 'large room' });
      this.checkRooms({ totalField: 'totalXLarge', upperField: 'upperXLarge', label: 'extra large room' });
      const totalRooms = this.getTotalRooms();
      if (totalRooms === 0) {
        this.createGeneralError(`Please pick how many rooms of each size you would like to heat and cool.`);
      }
    }
  }

  validateHVACData() {
    const { minTempDay, minTempNight, maxTempDay, maxTempNight, existingSeer, furnaceType, ductedDelivery } = this.form;
    if (this.touched.furnaceType) {
      this.isRequired('furnaceType', 'primary heating source');
      this.isEnumMember('furnaceType', FurnaceType);
    }

    if (
      this.touched.wholeHomeSystem &&
      this.layout !== LayoutType.MULTIPLE_ROOMS &&
      (ductedDelivery === DuctedDeliveryType.YES || ductedDelivery === DuctedDeliveryType.UNKNOWN) &&
      furnaceType !== FurnaceType.ELECTRIC_HEAT_PUMP
    ) {
      this.isRequired('wholeHomeSystem', 'central A/C');
      this.isEnumMember('wholeHomeSystem', WholeHomeSystem);
    }
    if (this.touched.numExistingSystems && SizingModeUtil.showNumExistingSystemsSelector(this.form)) {
      this.isRequired('numExistingSystems', 'number of existing systems');
      this.isEnumMember('numExistingSystems', NumExistingSystems);
    }
    if (this.touched.existingAcTonnage && SizingModeUtil.showExistingAcTonnageSelector(this.form)) {
      this.isRequired('existingAcTonnage', 'tonnage of existing system');
      this.isEnumMember('existingAcTonnage', ExistingAcTonnage);
    }
    if (minTempDay) {
      this.range('minTempDay', 'Daytime heating temperature', MIN_VALID_INDOOR_TEMP, MAX_VALID_INDOOR_TEMP);
    }
    if (minTempNight) {
      this.range('minTempNight', 'Nighttime heating temperature', MIN_VALID_INDOOR_TEMP, MAX_VALID_INDOOR_TEMP);
    }
    if (maxTempDay) {
      this.range('maxTempDay', 'Daytime cooling temperature', MIN_VALID_INDOOR_TEMP, MAX_VALID_INDOOR_TEMP);
    }
    if (maxTempNight) {
      this.range('maxTempNight', 'Nighttime cooling temperature', MIN_VALID_INDOOR_TEMP, MAX_VALID_INDOOR_TEMP);
    }
    if (existingSeer) {
      this.range('existingSeer', 'Existing SEER', MIN_EXISTING_SEER, MAX_EXISTING_SEER);
    }
  }

  validateSingleRoomData() {
    if (this.touched.roomSqFt) {
      this.isRequired('roomSqFt', 'room square footage');
      if (this.form.roomSqFt !== '') {
        this.range('roomSqFt', 'room square footage', MIN_VALID_ROOM_SQFT, MAX_VALID_ROOM_SQFT);
      }
    }
  }

  validateContactInfo() {
    const { phone, email } = this.form;
    if (email) {
      this.isEmail('email');
    } else if (this.touched.email) {
      this.isRequired('email', 'email');
    }
    if (!phone && this.touched.phone) {
      this.isPhone('phone');
    }
    if (phone) {
      this.isPhone('phone');
    } else if (this.touched.phone) {
      this.isRequired('phone', 'phone number');
    }
    if (this.touched.firstName) {
      this.isRequired('firstName', 'First Name');
    }
    if (this.touched.lastName) {
      this.isRequired('lastName', 'Last Name');
    }
  }

  checkRooms(args: { totalField: keyof FormFields; upperField: keyof FormFields; label: string }) {
    const { totalField, upperField, label } = args;
    const totalLabel = `total ${label}s`;
    const upperLabel = `top floor ${label}s`;
    if (this.touched[upperField] && this.form[upperField] !== '0' && this.form[upperField] !== '') {
      this.isRequired(upperField, upperLabel);
    }
    if (this.touched[totalField] && this.form[totalField] !== '0' && this.form[totalField] !== '') {
      this.isRequired(totalField, totalLabel);
      this.range(totalField, totalLabel, 0, 20);
      if (this.touched[upperField] && typeof Number(this.form[totalField]) === 'number') {
        this.range(upperField, upperLabel, 0, Number(this.form[totalField]));
      }
    }
  }
}
